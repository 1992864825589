<template>
  <div id="user.activate.resend">
    <b-row class="m-0 p-4">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card>
          <b-row v-if="send === false">
            <b-col md="12">
              <b-alert show variant="danger" class="text-center">
                User with email does not exist
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-else-if="send === true">
            <b-col md="12">
              <b-alert show variant="success" class="text-center">
                Email was send with the password reset code
              </b-alert>
            </b-col>
          </b-row>
          <email />
          <b-row>
            <b-col class="d-flex mt-2 justify-content-center"
              ><send-password-button
                :active="valid"
                v-on:clicked="sendReset()"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import email from "../form/password/email";
import sendPasswordButton from "../components/sendPasswordButton";
import logic from "@/scripts";
export default {
  name: "user.activate.resend",
  components: {
    email,
    sendPasswordButton,
  },
  computed: {
    ...mapGetters({
      form: "getPasswordSendForm",
      send: "getPasswordSend",
    }),
    valid() {
      return logic.input.user.password.send.validate(this.form.email);
    },
  },
  methods: {
    sendReset() {
      this.$store.dispatch("sendPasswordReset");
    },
  },
};
</script>

<style>
</style>