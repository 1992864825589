<template>
  <div id="user.form.password.email">
    <b-form-group class="mb-2" id="email-group">
      <label for="email-live"
        >{{ $t("user.form.password.email.label") }}:</label
      >
      <b-input-group>
        <b-form-input
          id="email-live"
          type="text"
          v-model="$v.email.$model"
          :state="validateState('email')"
          aria-describedby="email-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.email.$anyError" id="email-feedback">
        <small v-if="!$v.email.required" class="form-text text-danger">{{
          $t("user.form.password.email.required")
        }}</small>
        <small v-if="!$v.email.emailFormat" class="form-text text-danger">{{
          $t("user.form.password.email.email")
        }}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts";
export default {
  name: "user.form.password.email",
  computed: {
    ...mapGetters({
      form: "getPasswordSendForm",
    }),
    email: {
      get() {
        return this.form.email;
      },
      set(value) {
        this.$store.commit("setPasswordSendFormEmail", value);
      },
    },
  },
  validations: {
    email: {
      required,
      emailFormat() {
        return logic.input.user.password.send.email.emailFormat(this.form.email);
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
</style>